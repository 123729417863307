<template>
    <v-container>
        <v-layout>
            <v-flex md8 offset-md2>
                <v-card>
                    <v-card-title>
                        <h1 class="text-h5">Reuniao com Gestores</h1>
                    </v-card-title>
                    <v-list>
                        <template v-for="(journal, index) in journals">
                            <v-list-item :key="index"
                                three-line
                                link
                                :to="`/updates/${journal._id}`"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <h2 class="text-h5">{{journal.subject}}</h2>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <h3 class="text-subtitle-2">
                                            {{journal.manager.name}} -
                                            {{moment(journal.createdAt).format('DD/MM/YYYY HH:MM')}}
                                        </h3>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="my-2"
                            v-if="index !== journals.length - 1" :key="`a${index}`">
                            </v-divider>
                        </template>
                    </v-list>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

export default {
  data: () => ({
    journals: [],
  }),
  async mounted() {
    const { data } = await api.managers.listJournals();
    this.journals = data.journals;
  },
  methods: {
    moment,
  },
};
</script>
