<template>
    <v-container grid-list-md>
        <v-layout>
            <v-flex md8>
                <v-card>
                    <v-card-title>
                        <h1 class="text-h5">{{journal.subject}}</h1>
                    </v-card-title>
                    <v-card-text v-html="journal.content">

                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex md4>
                <v-card>
                    <v-card-text>
                    <router-link class="title-link" :to="`/managers/${manager._id}`">
                        <h2 class="text-h5">{{manager.name}}</h2>
                        <h2 class="text-subtitle-1">{{manager.document}}</h2>
                    </router-link>
                    <template>
                    <v-simple-table>
                        <tbody>
                        <tr>
                            <td>Total de fundos: </td>
                            <td>{{ manager.funds.length }}</td>
                        </tr>
                        <tr>
                            <td>PL total de fundos:</td>
                            <td>R$ {{formatNumber(manager.equity)}}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    </template>
                    <v-divider class="my-3"></v-divider>
                    <template>
                        <v-tabs
                            fixed-tabs
                            background-color="primary"
                            dark
                            v-model="tab"
                        >
                            <v-tab key="updates" :href="`#tab-updates`">
                            Outros Updates
                            </v-tab>
                            <v-tab key="fundos" :href="`#tab-fundos`">
                            Fundos
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items class="update-tabs" v-model="tab">
                            <v-tab-item
                                key="tab-updates"
                                value="tab-updates"
                            >
                                <v-responsive :height="200">
                                    <v-data-table
                                        :headers="headersJournals"
                                        :items="journals"
                                        sort-by="calories"
                                        :loading="loading"
                                        class="color-table"
                                        :items-per-page="5"
                                        hide-default-header
                                        :footer-props=" {
                                            'disable-items-per-page': true,
                                            'items-per-page-options': []
                                            }"
                                        dense
                                        >
                                        <template v-slot:item.subject="{ item }" >
                                            <router-link :to="`/funds/${item._id}`">
                                            <span class="text-no-wrap">
                                                {{item.subject}}
                                            </span>
                                            </router-link>
                                        </template>
                                    </v-data-table>
                                </v-responsive>
                            </v-tab-item>
                            <v-tab-item
                                key="tab-fundos"
                                value="tab-fundos"
                            >
                                <v-responsive :height="200">
                                    <v-data-table
                                    :headers="headers"
                                    :items="manager.funds"
                                    sort-by="calories"
                                    :loading="loading"
                                    class="color-table"
                                    :items-per-page="5"
                                    hide-default-header
                                    dense
                                    >
                                    <template v-slot:item.name="{ item }" >
                                        <router-link :to="`/funds/${item._id}`">
                                        <span class="text-no-wrap">
                                            {{item.name}}
                                        </span>
                                        </router-link>
                                    </template>
                                    </v-data-table>
                                </v-responsive>
                            </v-tab-item>
                        </v-tabs-items>
                    </template>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<style scoped>
    .title-link {
        color: #000;
        text-decoration: none;
    }
    .v-data-footer__select {
        display: none !important;
    }
</style>

<style>
    .update-tabs .v-data-footer__select {
        display: none !important;
    }
</style>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

export default {
  data: () => ({
    loading: true,
    tab: 'tab-updates',
    headers: [
      { text: 'Nome', value: 'name' },
    //   { text: 'Documento', value: 'document' },
    ],
    headersJournals: [
      { text: 'Título', value: 'subject' },
    ],
    journal: {},
    journals: [],
    manager: {},
    id: '',
  }),
  async created() {
    this.id = this.$route.params.id;

    const { data } = await api.managers.getJournalById(this.id);

    const { data: manager } = await api.managers.getById(data.manager);

    const journals = await api.managers.listJournalsByManager(manager._id);

    this.manager = manager;
    this.journal = data;
    this.journals = journals.data.journals;

    this.loading = false;
  },
  methods: {
    moment,
    formatNumber(num) {
      return num.replace('.', ',').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>
