var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',[_c('v-flex',{attrs:{"md8":""}},[_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.journal.subject))])]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.journal.content)}})],1)],1),_c('v-flex',{attrs:{"md4":""}},[_c('v-card',[_c('v-card-text',[_c('router-link',{staticClass:"title-link",attrs:{"to":("/managers/" + (_vm.manager._id))}},[_c('h2',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.manager.name))]),_c('h2',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.manager.document))])]),[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Total de fundos: ")]),_c('td',[_vm._v(_vm._s(_vm.manager.funds.length))])]),_c('tr',[_c('td',[_vm._v("PL total de fundos:")]),_c('td',[_vm._v("R$ "+_vm._s(_vm.formatNumber(_vm.manager.equity)))])])])])],_c('v-divider',{staticClass:"my-3"}),[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"updates",attrs:{"href":"#tab-updates"}},[_vm._v(" Outros Updates ")]),_c('v-tab',{key:"fundos",attrs:{"href":"#tab-fundos"}},[_vm._v(" Fundos ")])],1),_c('v-tabs-items',{staticClass:"update-tabs",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"tab-updates",attrs:{"value":"tab-updates"}},[_c('v-responsive',{attrs:{"height":200}},[_c('v-data-table',{staticClass:"color-table",attrs:{"headers":_vm.headersJournals,"items":_vm.journals,"sort-by":"calories","loading":_vm.loading,"items-per-page":5,"hide-default-header":"","footer-props":{
                                        'disable-items-per-page': true,
                                        'items-per-page-options': []
                                        },"dense":""},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
                                        var item = ref.item;
return [_c('router-link',{attrs:{"to":("/funds/" + (item._id))}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.subject)+" ")])])]}}])})],1)],1),_c('v-tab-item',{key:"tab-fundos",attrs:{"value":"tab-fundos"}},[_c('v-responsive',{attrs:{"height":200}},[_c('v-data-table',{staticClass:"color-table",attrs:{"headers":_vm.headers,"items":_vm.manager.funds,"sort-by":"calories","loading":_vm.loading,"items-per-page":5,"hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                                        var item = ref.item;
return [_c('router-link',{attrs:{"to":("/funds/" + (item._id))}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}])})],1)],1)],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }